import Project = require("Everlaw/Project");
import User = require("Everlaw/User");
import { MonitorLog } from "Everlaw/Heap/HeapRuntimeMonitor";
import { OrganizationId } from "Everlaw/MinimalOrganization";
import { isLocal } from "Everlaw/Server";

/**
 * Everlaw custom event properties. These properties are used in Heap Analytics for dashboards,
 * metrics, and filters. Any changes or updates to these properties must be done in coordination
 * with the Product team.
 */
interface HeapEventProperties {
    is_everlaw_user: boolean;
    vpc: string;
    project_id: Project.Id;
    database_id: number;
    organization_id: OrganizationId;
    is_project_admin: boolean;
    is_org_admin: boolean;
    is_cloud_mgmt_admin: boolean;
    is_db_admin: boolean;
}

// Copied from  https://developers.heap.io/reference/client-side-apis-overview
interface Heap {
    track: (event: string, properties?: MonitorLog) => void;
    identify: (identity: string) => void;
    resetIdentity: () => void;
    addUserProperties: (properties: Record<string, unknown>) => void;
    addEventProperties: (properties: Partial<HeapEventProperties>) => void;
    removeEventProperty: (property: string) => void;
    clearEventProperties: () => void;
    appid: string;
    userId: string;
    identity: string | null;
    config: unknown;
}

declare global {
    // Add heap to Window interface via interface merging
    interface Window {
        heap: Heap;
    }
}

// This method is used to initialize Heap and is copied from https://developers.heap.io/docs/web.
// It should be used in headHeader.jsp only to prevent duplicate heap instances.
// Since this block is essentially copy-pasted, we ignore eslint errors.
/* eslint-disable */
export function init() {
    // @ts-ignore
    window.heap = window.heap || [];
    // @ts-ignore
    heap.load = function (e, z, t) {
        // @ts-ignore
        window.heap.appid = e;
        // @ts-ignore
        window.heap.config = t = t || {};
        var r = t.forceSSL || "https:" === document.location.protocol,
            a = document.createElement("script");
        z = z || (r ? "https:" : "http:") + "//cdn.heapanalytics.com/js/heap-" + e + ".js";
        (a.type = "text/javascript"), (a.async = !0), (a.src = z);
        var n = document.getElementsByTagName("script")[0];
        // @ts-ignore
        n.parentNode.insertBefore(a, n);
        for (
            // @ts-ignore
            var o = function (e) {
                    return function () {
                        // @ts-ignore
                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                    };
                },
                p = [
                    "addEventProperties",
                    "addUserProperties",
                    "clearEventProperties",
                    "identify",
                    "resetIdentity",
                    "removeEventProperty",
                    "setEventProperties",
                    "track",
                    "unsetEventProperty",
                ],
                c = 0;
            c < p.length;
            c++
        ) {
            // @ts-ignore
            heap[p[c]] = o(p[c]);
        }
    };
    // @ts-ignore
    heap.load("2811285497", "/ver/${ASSET_VERSION}/Javascript/heap-2811285497.js");
}
/* eslint-enable */

// This method is used to init the metrics we want heap to collect.
export function initEventProps() {
    const heap = window.heap;
    heap.clearEventProperties();
    if (User.me && !User.me.impersonatorUsername) {
        const userId = User.me.id;
        const vpc = JSP_PARAMS.Server.vpc;

        if (vpc) {
            heap.identify(vpc + "-" + userId);
        }

        const isEverlawUser = User.me.isEverlawyer;

        let projectId;
        let databaseId;
        let organizationId;
        let isProjectAdmin;
        let isDbAdmin;
        let isOrgAdmin;
        let isCloudMgmtAdmin;

        if (Project.CURRENT) {
            projectId = Project.CURRENT.id;
            databaseId = Project.CURRENT.databaseId;
            organizationId = Project.CURRENT.owningOrganizationId;
            isProjectAdmin = User.me.isProjectAdmin();
            isDbAdmin = User.me.can("DB_ADMIN", Project.CURRENT);
        } else if (JSP_PARAMS.Help.orgId) {
            // On non-project pages, we try to get the org id from JSP_PARAMS.Help.orgId. Note that
            // organizationId will remain undefined on pages like admin.do.
            // TODO: JSP_PARAMS.Help.orgId is really only meant to be used by Help.tsx; we should
            //   ideally be getting this id from somewhere more canonical.
            organizationId = JSP_PARAMS.Help.orgId as OrganizationId;
        }

        if (organizationId !== undefined) {
            isOrgAdmin = User.me.isAdminOf(organizationId);
            isCloudMgmtAdmin = User.me.isCloudManagementAdminOf(organizationId);
        }

        heap.addEventProperties({
            project_id: projectId, // eslint-disable-line camelcase
            database_id: databaseId, // eslint-disable-line camelcase
            organization_id: organizationId, // eslint-disable-line camelcase
            is_project_admin: isProjectAdmin, // eslint-disable-line camelcase
            is_org_admin: isOrgAdmin, // eslint-disable-line camelcase
            is_cloud_mgmt_admin: isCloudMgmtAdmin, // eslint-disable-line camelcase
            is_db_admin: isDbAdmin, // eslint-disable-line camelcase
        });
        heap.addUserProperties({
            is_everlaw_user: isEverlawUser, // eslint-disable-line camelcase
            primary_organization_id: User.me.primaryOrg?.id, // eslint-disable-line camelcase
            vpc: vpc,
        });
    }
}

function getHeap(): Promise<Heap> {
    return new Promise((resolve, reject) => {
        const checkHeap = (retryCount: number = 0) => {
            if (window.heap) {
                return resolve(window.heap);
            } else if (retryCount >= 10) {
                return reject(new Error("HeapDataAnalytics is not available"));
            }
            setTimeout(checkHeap, 500, retryCount + 1);
        };
        checkHeap();
    });
}

export function logger(): Pick<Heap, "track"> {
    return {
        track: (event: string, properties?: MonitorLog) => {
            if (isLocal()) {
                console.log("Runtime Monitor:", event, properties);
            } else {
                getHeap().then((heap) => {
                    heap.track(event, properties);
                });
            }
        },
    };
}
